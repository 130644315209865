@import "../../../helpers//fonts/fonts.scss";
@import "../../../helpers/colors/colors.scss";

.Preview {
    width: 100%;
    height: auto;
    padding: 30px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Preview-title {
    font-family: 'Nutito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    padding: 5px 15px;
    border-radius: 10px;
    color: $yellow;
    background-color: $lightrose;
    background-image: linear-gradient(326deg, $darkviolet 0%, $purple 74%);
}

@media (max-width: 520px) {
    .Preview-title {
        font-size: 30px;
    }
}