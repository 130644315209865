@import "../../helpers/fonts/fonts.scss";
@import "../../helpers/colors/colors.scss";


.EatJournalRools {
    position: relative;
    padding-bottom: 650px;
    height: 100%;
    overflow: hidden;
}

.Eat-Rools-Wrapper {
    padding-top: 100px;
}

.EatJournal-Attention {
    font-family: 'Nutito-Light';
    font-style: normal;
    font-weight: 300;
    color: $purple;
    font-size: 18px;
    text-transform: uppercase;
    margin: 20px 5px;
}

.Rools-Title {
    font-family: 'Nutito-Regular';
    font-style: normal;
    font-weight: 400;
    color: $purple;
    width: 100%;
    text-align: left;
    text-transform: uppercase;
    position: relative;
    margin: 20px 0;
    padding-left: 25px;
   
}

.Rools-Title::before {
    content: "";
    display: block;
    position: absolute;
    width: 3.5px;
    height: 100%;
    max-height: 20px;
    left: 0px;
    bottom: 6px;
    background-color: $lightrose;
    background-image: linear-gradient(326deg, $darkviolet 0%, $purple 74%);

}

.Rools-Item {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 60px 0;
}

.Rools-Text {
    text-align: left;
    margin-left: 15px;
    font-family: 'Nutito-Light';
    font-style: normal;
    font-weight: 300;
    font-size: 21px;
    color: $purple;
    width: 70%;
}

.Rools-Img {
    display: block;
    max-width: 100px;
    max-height: 140px;
    margin-right: 20px;
    align-self: flex-start;
}

.Rools-Food-Content {
    display: flex;
    justify-content: space-between;
    padding: 20px 5px;
}

.Rools-Food-Wrapper {
    max-width: 45%;
    flex-direction: row;
    margin-top: 40px;
}

.Rools-Food-Item {
    display: flex;
    justify-content: left;
    font-family: 'Nutito-Light';
    font-style: normal;
    font-weight: 300;
    color: $purple;
    font-size: 22px;
    margin: 25px 0;
}

.Rools-Food-Img {
    display: block;
    margin-right: 10px;
    width: 100%;
    max-width: 30px;
    max-height: 30px;
}

@media (max-width: 550px) {
    .CetDrops {
     padding-bottom: 750px;
    }

    .Rools-Item {
        flex-direction: column;
        align-items: center;
    }

    .Rools-Img {
        align-self: center;
        margin-bottom: 20px;
    }

  }

  @media (max-width: 900px) {
    .Rools-Food-Content {
    flex-direction: column;
    align-items: center;
  }

  .Rools-Food-Item {
    flex-direction: column;
    align-items: center;
  }
  
  }

  @media (max-width: 500px) {
    .EatJournalRools {
     padding-bottom: 750px;
    }
  }
  