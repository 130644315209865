@import "../../helpers/colors/colors.scss";
@import "../../helpers/fonts/fonts.scss";

.Publications-Inner {
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
}

@media (max-width: 750px) {
 .Publications-Inner {
    flex-direction: column;
    align-items: center;
 }
   }