@import "../../../helpers/fonts/fonts.scss";
@import "../../../helpers/colors/colors.scss";


.Nav-container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin: 20px 0px;
}

.Nav-link {
    font-size: 21px;
    font-family: Nutito-Light;
    font-weight: 300;
    margin: 0px 10px;
    cursor: pointer;
    color: $yellow;
    transition: 0.2s;
    text-decoration: none;
}

.Nav-link.Hide {
    display: none;
}


@media (max-width: 899px) {
    .Nav-container {
        flex-wrap: wrap;
        flex-direction: column;
        width: 100%;
    }
  }

  @media (min-width: 900px) {
    .Nav-link:hover {
        color: $blue;
    }
  
}