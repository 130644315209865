@import "../../helpers/fonts/fonts.scss";
@import "../../helpers/colors/colors.scss";

.Title-Text {
    font-family: 'Nutito-Regular';
    font-style: normal;
    font-weight: 400;
    color: $purple;
    padding: 10px 25px;
    max-width: 600px;
    text-transform: uppercase;
    position: relative;
   
}

.Title-Text.yellow {
    color: $yellow;
}

.Title-Text.yellow::after {
    background-color: $yellow;
    background-image: linear-gradient(326deg, $blue 0%, $yellow 74%);
}



.Title-Text::after {
    content: "";
    display: block;
    width: 65%;
    height: 3px;
    background-color: $lightrose;
    background-image: linear-gradient(326deg, $darkviolet 0%, $purple 74%);
    bottom: -5px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.Title-Wrapper {
    display: flex;
    justify-content: center;
    margin: 5px;
}

@media (max-width: 400px) {
    .Title-Text {
        font-size: 25px;
    }
}

