@import "./helpers/colors/colors.scss";
@import "./helpers/fonts/fonts.scss";

body {
  margin: 0;
  font-family: 'Nutito-Light';
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
}

ul {
  list-style-type: none;
}

* {
  box-sizing: border-box;
}

*::before,
*::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

*ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  width: 100%;
  max-width: 1230px;
  margin: 0 auto;
  padding: 0 15px;
}

.section {
  padding: 35px 0;
}

// ScrollBar

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  box-shadow: 5px 5px 5px -5px rgba(34, 60, 80, 0.2) inset;
  background-color: #f9f9fd;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: linear-gradient(180deg, $darkviolet, $purple);
}

html,
body {
  scrollbar-color: $darkviolet $white; /* «цвет ползунка» «цвет полосы скроллбара» */
  scrollbar-width: thin;
}

