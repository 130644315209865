@font-face {
    font-family: 'Nutito-ExtraLight';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: local('Nutito-ExtraLight'),
         url('../fonts/Nunito-ExtraLight.woff') format('woff'),
         url('../fonts/Nunito-ExtraLight.woff2') format('woff2'),
}

@font-face {
    font-family: 'Nutito-Light';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local('Nutito-Light'),
         url('../fonts/Nunito-Light.woff') format('woff'),
         url('../fonts/Nunito-Light.woff2') format('woff2'),
}

@font-face {
    font-family: 'Nutito-Regular';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Nunito-Regular'),
         url('../fonts/Nunito-Regular.woff') format('woff'),
         url('../fonts/Nunito-Regular.woff2') format('woff2'),
}


