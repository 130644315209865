@import "../../../../helpers/fonts/fonts.scss";
@import "../../../../helpers/colors/colors.scss";


.SendMail-Wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.SendMail-Input {
    font-family: 'Nutito-Light';
    font-style: normal;
    font-weight: 300;
    display: block;
    height: 100%;
    width: 70%;
    font-size: 20px;
    padding: 10px 10px 10px 10px;
    background-color: $white;
    border-radius: 10px;
    transition: 0.2s;
    border: 3px solid $purple;
    cursor: pointer;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .SendMail-Input::placeholder {
    color: $purple;
    font-family: 'Nutito-Light';
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
  }
  
  .SendMail-Input:focus {
    outline: 0;
    color: $purple;
    border-color: $yellow;
  }

  .SendMail-Button {
    font-family: 'Nutito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    border: 3px solid $purple;
    border-radius: 0.6em;
    cursor: pointer;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    line-height: 1;
    margin: 0 10px;
    padding: 0.84em 1.5em;
    text-decoration: none;
    text-align: center;
    color: $purple;
}

.SendMail-Button:hover {
    color: $white;
    outline: 0;
  }

  .SendMail-Button:focus {
    outline: 0;
  }
  
  .SendMail-Button {
    -webkit-transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
    transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
  }
  .SendMail-Button:hover {
    box-shadow: 0 0 40px 40px $darkviolet inset;
    border: 3px solid $darkviolet;
  }

  @media (max-width: 990px) {
   .SendMail-Wrapper {
    flex-wrap: wrap;
   }
}


@media (max-width: 585px) {
  .SendMail-Button {
    margin-top: 20px;
  }
}
