.Intro-Tab {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 70px;
}

.CetTablets {
    position: relative;
    padding-bottom: 650px;
    height: 100%;
    overflow: hidden;
}

@media (max-width: 500px) {
   .CetTablets {
    padding-bottom: 750px;
   }
 }