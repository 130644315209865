@import "../../../helpers//fonts/fonts.scss";
@import "../../../helpers/colors/colors.scss";

.LekItem {
    width: 45%;
    height: auto;
    user-select: none;
    position: relative;
    z-index: 500;
    background: $white;
    background: linear-gradient(0deg, $darkviolet 0%, $purple 32%,);
    cursor: pointer;
    display: flex;
    margin-bottom: 100px;
    border-radius: 1%;
    transition: 0.5s;
    text-decoration: none;
    outline: none;
    opacity: 0.9;
    
}

.LekItem:active {
    text-decoration: none;
}

.LekItem:visited {
    text-decoration: none;
    color: inherit;
}

.LekItem::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    background: rgb(232,218,155);
    background: linear-gradient(351deg, rgba(255, 238, 162, 0.8) 4%, rgba(132,175,209,0.8) 28%, rgba(121, 25, 176, 0.8) 57%);
    opacity: 0;
    transition: 0.6s;
    border: 3px solid $yellow;
    border-radius: 1%;
}

.LekItem-content {
    display: flex;
    flex-direction: column;
    position: relative;
    height: auto;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.LekItem-marker {
   height: 40px;
   margin-right: 20px;
}

.LekItem-text {
    font-family: 'Nutito-Light';
    font-style: normal;
    font-weight: 300;
    font-size: 26px;
    color: $yellow;
}

.LekItem-subtext {
    text-align: left;
    padding-right: 5px;
    font-size: 20px;
    color: $yellow;
}

.lekItem-img {
    display: block;
    width: 100%;
    max-width: 330px;
    height: auto;
    z-index: 1;
    padding-top: 10px;
}


.LekItem-textBlock {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    max-width: 250px;
    margin-bottom: 10px;
}

.LekItem-select {
    text-transform: uppercase;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1300;
    left: 50%;
    transform: translateX(-50%);
    font-size: 40px;
    color: $yellow;
    font-family: 'Nutito-Regular';
    font-style: normal;
    font-weight: 400;
    border: 3px solid $yellow;
    padding: 15px 30px;
    border-radius: 50px;
    opacity: 0;
    transition: 0.6s;
}

.LekItem-textWrapper {
    width: 100%;
    padding: 5px 5px 15px 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
}

.Mishkaz {
    display: block;
    position: absolute;
    top: 5%;
    right: 27%;
    transform: translateX(-27%);
   max-width: 150px;
   z-index: 1500;
   opacity: 0;
   transition: 0.6s;
}

@media (max-width: 700px) {
    .LekItem {
        width: 80%;
        margin-bottom: 20px;
    }
}

@media (max-width: 350px) {
    .LekItem-text {
        font-size: 20px;
    }

    .LekItem-subtext {
        font-size: 17px;
    }

    .LekItem-marker {
        height: 30px;
        margin-right: 10px;
     }
}

@media (min-width: 900px) {
    .LekItem:hover {
        text-decoration: none;
    }

    .LekItem:hover::after {
        opacity: 1;
    }
    
    .LekItem:hover .LekItem-select {
        opacity: 1;
    }
    
    .LekItem:hover .Mishkaz {
        opacity: 0.9;
    }
    
    .LekItem:hover {
        transform: translateY(-10px);
        transition: 0.5s;
    }
  }