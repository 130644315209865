@import "../../../helpers/colors/colors.scss";
@import "../../../helpers/fonts/fonts.scss";

.Post {
    position: relative;
    padding-bottom: 650px;
    height: 100%;
    overflow: hidden;
}

.Post-Wrapper {
    padding-top: 100px;
}

.Post-Content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 40px 0px;
}

.Post-Image {
    display: block;
    max-width: 800px;
    margin-top: 40px;
}

.Post-Text {
    text-align: left;
    max-width: 800px;
    margin-top: 30px;
}

.Post-Title {
    font-family: 'Nutito-Regular';
    font-style: normal;
    font-weight: 400;
    color: $darkviolet;
    margin: 20px 0px;
    font-size: 28px;
}

.Post-Paragraph {
    font-family: 'Nutito-Light';
    font-style: normal;
    font-weight: 300;
    color: $darkviolet;
}

@media (max-width: 830px) {
    .Post-Image {
        width: 100%;
    }
}

@media (max-width: 500px) {
    .Post {
     padding-bottom: 750px;
    }
  }