@import "../../../helpers/fonts/fonts.scss";
@import "../../../helpers/colors/colors.scss";

.EatJournal {
    margin-top: 60px;
}

.EatJournal-Img-Wrapper {
    width: 50%;
    max-width: 600px;
}

.EatJournal-Inner {
    display: flex;
    justify-content: space-between;
}

.EatJournal-Img {
    width: 100%;
    max-width: 605px;
    height: auto;
    display: block;
}

.EatJournal-Content {
    width: 50%;
}

.EatJournal-Title {
    text-align: left;
    font-family: 'Nutito-Light';
    font-style: normal;
    font-weight: 300;
    position: relative;
    padding: 5px 0px 5px 30px;
    font-size: 23px;
    color: $lightdark;
}


.EatJournal-Title::before {
    content: "";
    display: block;
    position: absolute;
    width: 3px;
    height: 100%;
    max-height: 90px;
    left: 5px;
    bottom: 6px;
    background-color: $lightrose;
    background-image: linear-gradient(326deg, $darkviolet 0%, $purple 74%);
}

.EatJournal-Button {
    font-family: 'Nutito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    border: 3px solid $purple;
    border-radius: 0.6em;
    cursor: pointer;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    line-height: 1;
    margin: 20px 20px 20px 0px;
    padding: 1.2em 2.8em;
    text-decoration: none;
    text-align: center;
    color: $purple;
}

.EatJournal-Download {
    display: flex;
    justify-content: center;
}


  .EatJournal-Button:focus {
    outline: 0;
  }
  
  .EatJournal-Button {
    -webkit-transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
    transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
  }
 
  @media (max-width: 990px) {
    .EatJournal-Inner {
        flex-direction: column;
        align-items: center;
    }

    .EatJournal-Content {
        width: 100%;
    }

    .EatJournal-Img-Wrapper {
        width: 100%;
    }
}

@media (max-width: 400px) {
   .EatJournal-Title {
    font-size: 19px;
   }

   .EatJournal-Button {
    margin: 20px 0;
   }
}

@media (min-width: 900px) {
    .EatJournal-Button:hover {
        color: $white;
        outline: 0;
      }

      .EatJournal-Button:hover {
        box-shadow: 0 0 40px 40px $darkviolet inset;
        border: 3px solid $darkviolet ;
      }

  }