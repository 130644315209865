@import "../../helpers/colors/colors.scss";
@import "../../helpers/fonts/fonts.scss";

.Intro {
    height: 100vh;
    width: 100%;
    background: url("../../helpers/Images/Intro/introImage3.webp") center no-repeat;
    background-size: cover;
    padding-top: 67px;
    min-height: 700px;
}

.Intro-Inner-Tab {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 50px;
    position: relative;
}

.Intro.Tab {
    height: 100%;
    padding-bottom: 30px;
}


.Intro-inner {
    display: flex;
    flex-direction: column;
}

.Intro-select {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

@media (max-width: 1050px) {
   .Intro-Inner-Tab {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0;
   }
}

@media (max-width: 700px) {
    .Intro-select {
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .Intro {
        height: 100%;
        padding-bottom: 200px;
    }
}

@media (max-width: 899px) {

    .Intro {
        padding-bottom: 100px;
        height: 100%;
    }
}
