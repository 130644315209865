@import "../../../helpers//fonts/fonts.scss";
@import "../../../helpers/colors/colors.scss";

.IntroImage-Wrapper {
    width: 40%;
    position: absolute;
    left: 30px;
    top: 50px;
    z-index: 1000;
    user-select: none;
    cursor: pointer;
    transition: 0.5s;
}

.Dialog-Buble-Wrapper {
    position: absolute;
    top: -70px;
    right: -50px;
    z-index: 1700;
}

.Dialog-Buble {
    position: relative;
    font-size: 18px;
    padding: 13px 0px 0px 18px;
    width: 230px;
	height: 130px;
	text-align: left;
	background-color: rgba($color: $purple, $alpha: 0.9);
	border: 8px solid $blue;
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
    color: $yellow;
    opacity: 0;
    font-family: 'Nutito-Light';
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    transition: 0.5s;
}

.Dialog-Buble:before { 
	content: ' '; 
	position: absolute; 
	width: 0; 
	height: 0; 
	left: 22px; 
	top: 120px; 
	border: 25px solid; 
	border-color: $blue transparent transparent $blue; 
}

.Dialog-Buble.active {
    opacity: 1;
}

@media (max-width: 1050px) {
    .IntroImage-Wrapper {
       position: relative;
       left: 0;
       width: 60%;
       margin-bottom: 50px;
    }

    .IntroImage {
        width: 100%;
    }
}

@media (max-width: 650px) {
    .Dialog-Buble {
        font-size: 16px;
        width: 180px;
        height: 100px;
        left: -20px;
        top: 30px;
    }

    .Dialog-Buble:before { 
        left: 10px; 
        top: 90px; 
       
    }

    .IntroImage-Wrapper {
        width: 80%;
     }
    
}

@media (max-width: 400px) {
    .Dialog-Buble {
        font-size: 14px;
        width: 165px;
        height: 100px;
        left: -10px;
        bottom: 10px;
    }

    .Dialog-Buble:before { 
        left: 10px; 
        top: 90px; 
       
    }
    
}

@media (min-width: 900px) {
    .IntroImage-Wrapper:hover::after {
        opacity: 0.9;
    }
    
    .IntroImage-Wrapper:hover {
       transform: translateY(-7px);
       transition: 0.5s;
    }

  }


