@import "../../colors/colors.scss";
@import "../../fonts/fonts.scss";

.LinkButton {
    font-family: 'Nutito-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: transparent;
    border: 3px solid $purple;
    border-radius: 0.6em;
    cursor: pointer;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    align-self: center;
    line-height: 1;
    margin: 20px 20px 20px 0px;
    padding: 1.2em 2.8em;
    text-decoration: none;
    text-align: center;
    color: $purple;
}

.LinkButton.Yellow {
  border: 3px solid $yellow;
  color: $yellow;
}

.LinkButton:focus {
    outline: 0;
  }
  
  .LinkButton {
    -webkit-transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
    transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
  }


  @media (max-width: 400px) {
   .LinkButton {
    margin: 20px 0;
    padding: 1.2em 2.0em;
   }
 }

 @media (min-width: 900px) {

  .LinkButton:hover {
    color: $white;
    outline: 0;
  }

  .LinkButton:hover {
    box-shadow: 0 0 40px 40px $darkviolet inset;
    border: 3px solid $darkviolet ;
  }

  .LinkButton.Yellow:hover {
    box-shadow: 0 0 40px 40px $yellow inset;
    border: 3px solid $yellow;
    color: $purple; 
  }

}