@import "../../../helpers//fonts/fonts.scss";
@import "../../../helpers/colors/colors.scss";



.Advantages {
  display: flex;
  justify-content: left;
  align-items: left;
  flex-direction: column;
}

.Advantages.Hide {
  display: none;
}

.Advantages-Button {
  cursor: pointer;
  border: 0;
  background-color: inherit;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 23px;
  color: $lightyellow;
  text-align: left;
  width: 100%;
  user-select: none;
  font-size: 25px;
  font-family: Nutito-Light;
  font-weight: 300;
  transition: 0.3s;
  display: flex;
  align-items: center;
}

.Advantages-Button.purple {
  color: $purple;
}

.Advantages-Button.active {
  color: $yellow;
  font-size: 25px;
  font-family: Nutito-Light;
  font-weight: 300;
  transition: 0.3s;
}

.Advantages-Button.active {
  color: $blue;
}

.Dropdown-Menu {
  display: flex;
  margin-left: 15px;
  background-color: $lightyellow;
  border-radius: 15px;
}

.Drop-Menu-Text {
  font-size: 20px;
  color: $purple;
  text-align: left;
  user-select: none;
  width: 100%;
  padding: 15px 20px 15px 20px;
  font-family: Nutito-Light;
  font-weight: 300;
}

.Advantages-Menu-Subtext {
  margin: 5px 0 5px 0px;
  font-family: Nutito-Light;
  font-weight: 300;
}

.Advantages-Menu-AdditionalText {
  margin: 0;
  font-family: Nutito-Light;
  font-weight: 600;
  font-style: normal;
  color: $purple;
  background-color: $blue;
  border-radius: 5px;
  padding: 0;
}

@media (max-width: 426px) {
  .Advantages-Button {
    font-size: 20px;
  }

  .Advantages-Button.active {
    font-size: 20px;
  }
  
}

@media (min-width: 900px) {
  .Advantages-Button:hover  {
    color: $blue;
  }
  
  .Advantages-Button:hover .Plus-Icon::after {
    background-color: $blue;
  }
  
  .Advantages-Button:hover .Plus-Icon::before {
    background-color: $blue;
  }
}