@import "../../../../helpers/fonts/fonts.scss";
@import "../../../../helpers/colors/colors.scss";



.NavMobile {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.NavMobile-Hide {
  display: none;
}

.NavMobile-Button {
  cursor: pointer;
  border: 0;
  background-color: inherit;
  display: flex;
  justify-content: flex-start;
  color: $yellow;
  text-align: left;
  width: 100%;
  user-select: none;
  font-size: 26px;
  font-family: Nutito-Light;
  font-weight: 300;
  transition: 0.3s;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}

.NavMobile-Button.active {
  color: $yellow;
  font-family: Nutito-Light;
  font-weight: 300;
  transition: 0.3s;
}

.NavMobile-Button.active {
  color: $blue;
}

.Mobile-Dropdown-Menu {
  display: flex;
  flex-direction: column;
  padding: 0;
  padding-left: 10px;
  margin: 10px 0px 0px 0px;
}

.Mobile-Menu-Text {
  font-size: 23px;
  color: $yellow;
  text-align: left;
  user-select: none;
  margin: 20px 0px;
  width: 100%;
  font-family: Nutito-Light;
  font-weight: 300;
}


@media (max-width: 899px) {
  .NavMobile {
      display: block;
  }
}

@media (min-width: 900px) {
  .NavMobile {
      display: none;
  }
}



