@import "../../colors/colors.scss";

.Questions {
    background-color: $lightrose;
    background-image: linear-gradient(326deg, $darkviolet 0%, $purple 74%);
}

.Questions-inner {
    margin: 40px 0px;
    display: flex;
    flex-direction: column;
}