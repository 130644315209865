@import "../../fonts/fonts.scss";
@import "../../colors/colors.scss";

.SubTitle-Text {
    font-family: 'Nutito-Regular';
    font-style: normal;
    font-weight: 400;
    color: $purple;
    width: 100%;
    text-align: left;
    text-transform: uppercase;
    position: relative;
    margin: 20px 0;
    padding-left: 25px;
}

.SubTitle-Text::before {
    content: "";
    display: block;
    position: absolute;
    width: 3.5px;
    height: 100%;
    max-height: 20px;
    left: 0px;
    bottom: 6px;
    background-color: $lightrose;
    background-image: linear-gradient(326deg, $darkviolet 0%, $purple 74%);
}

@media (max-width: 513px) {
    .SubTitle-Text::before {
        max-height: 50px;
    }
  }

@media (max-width: 400px) {
    .SubTitle-Text {
        font-size: 20px;
    }
  }