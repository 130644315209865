@import "../../helpers/fonts/fonts.scss";
@import "../../helpers/colors/colors.scss";

.Footer {
    height: 600px;
    width: 100%;
    background-color: $lightrose;
    background-image: linear-gradient(326deg, $darkviolet 0%, $purple 74%);
    position: absolute;
    bottom: 0;
    z-index: 850;
    padding: 35px 0;
  }
  
  .Footer-Inner {
    display: flex;
    justify-content: space-between;
  }

  @media (max-width: 500px) {
    .Footer-Inner {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .Contacts-Container {
      margin-top: 20px;
    }

    .Footer {
      height: 700px;
    }
}