.Logo-Borimed-Img {
  display: block;
  max-width: 230px;
  height: auto;
  cursor: pointer;
}

.Logo-Borimed {
  margin: 5px 0 25px 0;
}
