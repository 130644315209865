.CetDrops {
    position: relative;
    padding-bottom: 650px;
    height: 100%;
    overflow: hidden;
}

@media (max-width: 500px) {
    .CetDrops {
     padding-bottom: 750px;
    }
  }