@import "../../../helpers//fonts/fonts.scss";
@import "../../../helpers/colors/colors.scss";

.LekItem-Tab {
    width: 40%;
    min-width: 500px;
    height: auto;
    user-select: none;
    z-index: 500;
    display: flex;
    margin-bottom: 100px;
    border-radius: 1%;
}

.LekItem-content-Tab {
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.LekItem-marker-Tab {
    height: 40px;
    margin: 0px 20px 0 10px ;
}

.LekItem-text-Tab {
    font-family: 'Nutito-Light';
    font-style: normal;
    font-weight: 300;
    font-size: 26px;
    color: $lightyellow;
}

.lekItem-img-Tab {
    display: block;
    width: 100%;
    max-width: 450px;
    height: auto;
    z-index: 1;
    padding-top: 10px;
}

.LekItem-textBlock-Tab {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 10px;
}

.LekItem-textWrapper-Tab {
    width: 100%;
    padding: 20px 50px 15px 30px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: left;
    background-color: $lightrose;
    background-image: linear-gradient(326deg, $darkviolet 0%, $purple 74%);
    opacity: 0.9;
}

@media (max-width: 1050px) {
    .LekItem-Tab {
        width: 60%;
        margin-top: 20px;
    }
}

@media (max-width: 650px) {
    .LekItem-Tab {
        width: 100%;
        margin: 0px 30px;
    }
    
}

@media (max-width: 550px) {
    .LekItem-textWrapper-Tab {
        width: 80%;
    }
    
}

@media (max-width: 426px) {
    .LekItem-textWrapper-Tab {
        width: 57%;
        padding: 20px 20px 15px 10px;
    }

    .lekItem-img-Tab {
        width: 70%;
    }

    .LekItem-text-Tab {
        font-size: 20px;
    }

    .LekItem-marker-Tab {
        height: 30px;
    }

    
}