@import "../../../helpers/colors/colors.scss";
@import "../../../helpers/fonts/fonts.scss";

.Logo-Img {
  display: block;
  max-width: 150px;
  height: auto;
  cursor: pointer;
  position: absolute;
}

.Logo-Text {
  font-family: 'Nutito-Regular';
  font-style: normal;
  font-weight: 400;
  position: absolute;
  left: 60px;
  top: -1px;
  color: $dirtyyellow;
  transition: 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
}

.Logo-Text-Title {
  margin: 0;
  font-size: 22px;
}

.Logo-Text-Subtext {
  margin: 0;
  font-size: 17px;
}

.Logo-Link {
  text-decoration: none;
  position: absolute;
  left: 0;
  top: 10px;
}

.Logo-Link-Inner {
  position: relative;
}

@media (min-width: 900px) {
  .Logo-Text:hover {
      color: $blue;
  }

}