@import "../../colors/colors.scss";
@import "../../fonts/fonts.scss";


.DosageUsage {
    background-color: $lightrose;
    background-image: linear-gradient(326deg, $darkviolet 0%, $purple 74%);
}

.DosageUsage-inner {
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
}

.DosageUsage-ImageWrapper {
    margin: 50px 0px 30px 0px;
    display: flex;
    justify-content: center;
}

.DosageUsage-Umbrella {
    display: block;
    width: 100%;
    max-width: 900px;
    justify-content: center;
    align-items: center;
}

.DosageUsage-TextWrapper {
    text-align: left;
    padding: 30px 50px;
    font-family: 'Nutito-Light';
    font-style: normal;
    font-weight: 300;
    color:  $purple;
    background-color: $white;
    border-radius: 20px;
    font-size: 21px;
}

.DosageUsage-AddText {
    background-color: $blue;
    border-radius: 5px;
    font-style: normal;
}

@media (max-width: 400px) {
    .DosageUsage-TextWrapper{
        font-size: 18px;
    }
}
