@import "../../../helpers/colors/colors.scss";
@import "../../../helpers/fonts/fonts.scss";

.NavDrop {
  max-width: 300px;
  position: relative;
  margin: 0px 0px 5px 0px;
  z-index: 1500;
}

.NavDrop-Hide {
  display: none;
}

.NavDrop-Button {
  font-size: 21px;
  font-family: "Nutito-Light";
  font-weight: 300;
  font-style: normal;
  color: $yellow;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  z-index: 1200;
  transition: 0.5s;
  padding: 0 15px;
}

.NavDrop-Button::before {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  border-top: 2px solid $blue;
  border-left: 2px solid $blue;
  position: absolute;
  top: 5px;
  left: -4px;
  z-index: 1200;
  transform: rotate(-135deg);
  transition: 0.3s;
}

.NavDrop-Button.active {
  opacity: 0.1;
  transition: 0.5s;
  color: $blue;
}

.NavDrop-Button.active::before {
  border-color: $blue;
  transform: rotate(43deg);
  top: 10px;
}

.NavDrop-Menu {
  padding: 10px 10px 15px 15px;
  background-color: $lightrose;
  background-image: linear-gradient(326deg, $darkviolet 0%, $purple 74%);
  border-radius: 5px;
  text-align: left;
  min-width: 270px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  left: -10px;
}

.NavDrop-Link {
  font-size: 21px;
  font-family: "Nutito-Light";
  font-weight: 300;
  font-style: normal;
  text-align: left;
  user-select: none;
  text-decoration: none;
  max-width: 100%;
  margin-bottom: 10px;
  transition: 0.2s;
  position: relative;
  z-index: 1;
  color: $yellow;
}

@media (max-width: 900px) {
  .NavDrop {
      display: flex;
      margin-bottom: 200px;
      display: none;
  }
}


@media (min-width: 900px) {
  
  .NavDrop-Button:hover::before {
    border-color: $blue;
    transform: rotate(43deg);
    top: 10px;
  }

  .NavDrop-Link:hover {
    color: $blue;
  }
  
}
