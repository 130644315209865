@import "../../helpers/fonts/fonts.scss";
@import "../../helpers/colors/colors.scss";

.Home {
    background-color: $white;
    position: relative;
    overflow: hidden;
    position: relative;
    height: 100%;
    padding-bottom: 600px;
  }
  
